import { socialApi, socialApiPrivate } from '../util/http-api';
import type { components } from './types/social-api-types';
import type { User } from '../types';

async function getAchievementBySlug(slug: string) {
  return socialApi.get<components['schemas']['Achievements']>(
    `/achievements/${slug}/`
  );
}

async function getUserAchievementBySlug(slug: string, userId: number) {
  return socialApi.get<components['schemas']['ProfileAchievementDetail']>(
    `/profiles/${userId}/achievements/${slug}/`
  );
}

async function markAchievementAsShown(slug: string) {
  return socialApi.post(`/account/achievements/${slug}/mark_as_shown/`, {});
}

async function getFeatureFlags() {
  return socialApi.get<components['schemas']['FeatureFlagsResponse']>(
    '/flags/'
  );
}

function getUser(id: number): Promise<User> {
  return socialApi.get(`/profiles/${id}/`);
}

function banUser(
  id: number,
  ban: components['schemas']['BanUserRequestRequest']
): Promise<null> {
  return socialApi.post(`/profiles/${id}/ban/`, ban);
}

function banUserPrivate(
  id: number,
  ban: components['schemas']['PrivateCreateBanRequestRequest']
): Promise<components['schemas']['PrivateUserCurrentBan']> {
  return socialApiPrivate.post(`/users/${id}/ban/`, ban);
}

function unbanUser(id: number): Promise<null> {
  return socialApi.post(`/profiles/${id}/unban/`, {});
}

function unbanUserPrivate(
  id: number
): Promise<components['schemas']['PrivateUserCurrentBan']> {
  return socialApiPrivate.post(`/users/${id}/unban/`, {});
}

function getAccessToken(): Promise<{
  access_token: string;
}> {
  return socialApiPrivate.get(`/me/`);
}

const socialApiAdapter = {
  getAchievementBySlug,
  getUserAchievementBySlug,
  markAchievementAsShown,
  getFeatureFlags,
  getUser,
  banUser,
  banUserPrivate,
  unbanUser,
  unbanUserPrivate,
  getAccessToken,
};

export default socialApiAdapter;

export type SocialApiAdapter = typeof socialApiAdapter;
